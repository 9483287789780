import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

/** @deprecated use `className="headline-l"` instead */
export const H3Css = css`
  font-size: 2.5rem; // 40px
  line-height: 4rem; // 64px
  font-weight: normal;
`

/** @deprecated use `className="headline-l"` instead */
export const H3CssObject = parseCssString(H3Css.styles)

/** @deprecated use `className="headline-l"` instead */
export const H3 = styled.h3`
  ${H3Css}
`

/** @deprecated use `className="headline-l-strong"` instead */
export const H3StrongCss = css`
  ${H3Css}
  font-weight: 600;
`

/** @deprecated use `className="headline-l-strong"` instead */
export const H3StrongCssObject = parseCssString(H3StrongCss.styles)

/** @deprecated use `className="headline-l-strong"` instead */
export const H3Strong = styled.h3`
  ${H3StrongCss}
`

/** @deprecated use `className="headline-l"` instead */
export const H3SerifCss = css`
  ${H3Css}
  font-family: var(--font-serif);
`

/** @deprecated use `className="headline-l"` instead */
export const H3SerifCssObject = parseCssString(H3SerifCss.styles)

/** @deprecated use `className="headline-l"` instead */
export const H3Serif = styled.h3`
  ${H3SerifCss}
`
