import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

/** @deprecated use `className="body"` instead */
export const BodyCss = css`
  font-size: 1rem; // 16px
  line-height: 1.5rem; // 24px
  font-weight: normal;
  letter-spacing: 0.2px;
`
/** @deprecated use `className="body"` instead */
export const Body = styled.p`
  ${BodyCss}
`
/** @deprecated use `className="body"` instead */
export const BodyCssObject = parseCssString(BodyCss.styles)

/** @deprecated use `className="body-strong"` instead */
export const BodyStrongCss = css`
  ${BodyCss}
  font-weight: 600;
`
/** @deprecated use `className="body-strong"` instead */
export const BodyStrong = styled.p`
  ${BodyStrongCss}
`
/** @deprecated use `className="body-strong"` instead */
export const BodyStrongCssObject = parseCssString(BodyStrongCss.styles)

/** @deprecated use `className="small"` instead */
export const BodySmallCss = css`
  color: inherit;
  font-size: 0.875rem; // 14px
  line-height: 1.5rem; // 24px
  font-weight: normal;
  letter-spacing: 0.3px;
`
/** @deprecated use `className="small"` instead */
export const BodySmall = styled.p`
  ${BodySmallCss};
`
/** @deprecated use `className="small"` instead */
export const BodySmallCssObject = parseCssString(BodySmallCss.styles)

/** @deprecated use `className="small"` instead */
export const LabelCss = css`
  font-size: 0.875rem; // 14px
  line-height: 1.5rem; // 24px
  font-weight: 600;
  letter-spacing: 0.3px;
`
/** @deprecated use `className="small"` instead */
export const Label = styled.p`
  ${LabelCss};
`
/** @deprecated use `className="small"` instead */
export const LabelCssObject = parseCssString(LabelCss.styles)

/** @deprecated use `className="small-mono"` instead */
export const SubtitleMonoCss = css`
  font-size: calc(0.875rem); // 14px
  line-height: 1.5rem; // 24px
  font-weight: normal;
  font-family: var(--font-mono);
  letter-spacing: 0.4px;
  text-rendering: geometricPrecision;
`
/** @deprecated use `className="small-mono"` instead */
export const SubtitleMono = styled.p`
  ${SubtitleMonoCss}
`
/** @deprecated use `className="small-mono"` instead */
export const SubtitleMonoCssObject = parseCssString(SubtitleMonoCss.styles)

/** @deprecated use `className="caption"` instead */
export const CaptionRegularCss = css`
  font-size: 0.75rem; // 12px
  line-height: 1rem; // 16px
  font-weight: normal;
  letter-spacing: 1px;
`
/** @deprecated use `className="caption"` instead */
export const CaptionRegular = styled.p`
  ${CaptionRegularCss}
`
/** @deprecated use `className="caption"` instead */
export const CaptionRegularCSSObject = parseCssString(CaptionRegularCss.styles)

/** @deprecated use `className="caption-mono"` instead */
export const CaptionMonoCSS = css`
  ${CaptionRegularCss}
  font-family: var(--font-mono);
`
/** @deprecated use `className="caption-mono"` instead */
export const CaptionMono = styled.p`
  ${CaptionMonoCSS}
`
/** @deprecated use `className="caption-mono"` instead */
export const CaptionMonoCSSObject = parseCssString(CaptionMonoCSS.styles)

/** @deprecated use `className="overtext"` instead */
export const OverlineCss = css`
  font-size: 0.625rem; // 10px
  line-height: 1rem; // 16px
  font-weight: normal;
  letter-spacing: 5px;
  text-transform: uppercase;
`
/** @deprecated use `className="overtext"` instead */
export const Overline = styled.p`
  ${OverlineCss}
`
/** @deprecated use `className="overtext"` instead */
export const OverlineCssObject = parseCssString(OverlineCss.styles)
