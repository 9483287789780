import { forwardRef, ForwardedRef, ButtonHTMLAttributes } from 'react'

import {
  mapTrackingKeysToDataAttributes,
  Tracking,
} from '../../../../utils/tracking-utils'
import { LoadingIndicator } from '../../loading-indicator'

import { Adornment } from './shared/adornment'
import { buttonStyles } from './shared/button-styles'
import type { ComponentProps } from './shared/types'

export type ButtonProps = Tracking &
  ComponentProps<ButtonHTMLAttributes<HTMLButtonElement>>

/**
 * Buttons communicate actions that users can take. They are typically placed throughout your UI, in places like:
 *
 * - Modal windows
 * - Forms
 * - Cards
 * - Toolbars
 */
export const Button = forwardRef(
  (props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const {
      variant = 'primary',
      size = 'regular',
      color = 'primary',
      children,
      disabled = false,
      loading = false,
      grow = false,
      inverse = false,
      onClick,
      type = 'button',
      testId = 'button',
      endAdornment,
      startAdornment,
      tracking,
      selectorId,
      ...rest
    } = props
    const { state, className } = buttonStyles({
      color,
      inverse,
      disabled,
      grow,
      loading,
      size,
      variant,
    })

    return (
      <button
        ref={ref}
        disabled={state !== 'default'}
        onClick={onClick}
        type={type}
        data-testid={testId}
        {...(selectorId ? { 'data-selector-id': selectorId } : {})}
        {...rest}
        className={className}
        {...mapTrackingKeysToDataAttributes({
          status: 'on',
          click_type: 'Button',
          ...tracking,
        })}
      >
        {state === 'loading' ? (
          <div className="absolute inset-0 flex items-center justify-center">
            <LoadingIndicator
              size="small"
              color={!inverse && variant === 'primary' ? 'inverse' : 'neutral'}
            />
          </div>
        ) : null}
        <Adornment adornment={startAdornment} size={size} />
        <span style={{ opacity: state === 'loading' ? 0 : 1 }}>{children}</span>
        <Adornment adornment={endAdornment} size={size} />
      </button>
    )
  }
)
