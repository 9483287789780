import cx from 'classnames'
import * as React from 'react'
import { StrictExtract } from 'ts-essentials'

import { Color, EquitySemanticColor } from '../../../../particles/colors'
import { IconType } from '../../../../particles/icons/lib/icons'
import { Icon } from '../../icon'

export type TextInputColor = StrictExtract<
  EquitySemanticColor,
  'primary' | 'success' | 'error' | 'warning'
>

export type TextInputSize = 'small' | 'large'

const getPadding = (
  size: TextInputSize,
  hasStartAdornment: boolean,
  hasEndAdornment: boolean
): string => {
  return [
    size === 'large' ? 'py-[11px]' : 'py-[7px]',
    hasStartAdornment ? 'pl-[44px]' : 'pl-16',
    hasEndAdornment ? 'pr-[44px]' : 'pr-16',
  ].join(' ')
}

const getFontSize = (size: TextInputSize): string => {
  return size === 'large' ? 'body' : 'small'
}

const filledBorderColorMap: Record<TextInputColor, string> = {
  primary: 'border-primary-200 focus:border-primary-600',
  success: 'border-success-200 focus:border-success-600',
  error: 'border-error-200 focus:border-error-600',
  warning: 'border-warning-400 focus:border-warning-600',
}

const borderColorMap: Record<TextInputColor, string> = {
  primary: 'border-neutral-200 focus:border-primary-600',
  success: 'border-success-300 focus:border-success-600',
  error: 'border-error-300 focus:border-error-600',
  warning: 'border-warning-300 focus:border-warning-600',
}

const getBorderColor = (color: TextInputColor, highlight: boolean): string =>
  highlight ? filledBorderColorMap[color] : borderColorMap[color]

const backgroundColorMap: Record<TextInputColor, string> = {
  primary: 'bg-primary-100',
  success: 'bg-success-100',
  error: 'bg-error-100',
  warning: 'bg-warning-100',
} as const

const getBackgroundColor = (
  color: TextInputColor,
  highlight: boolean
): string => (highlight ? backgroundColorMap[color] : 'enabled:bg-neutral-0')

type Adornment =
  | {
      variant: 'icon'
      icon: IconType
      color: Color
      onClick?: () => void
    }
  | {
      variant: 'text'
      text: string
      onClick?: () => void
    }

export type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'style' | 'className' | 'size' | 'value'
> & {
  /** Optional property to define which semantic color to use. Defaults to `"primary"` */
  color?: TextInputColor
  /** There are 2 different sizes. Affects font-size and padding. Defaults to `"large"` */
  size?: TextInputSize
  /** If a value is defined it means the text input is controlled and cannot be updated directly */
  value?: string
  /** Affects the border and background-color */
  highlight?: boolean
  /** Optional object to allow adding an icon or text  at the start of the text input */
  startAdornment?: Adornment
  /** Optional object to allow adding an icon or text at the end of the text input */
  endAdornment?: Adornment

  /** Optional boolean to align text right */
  textRight?: boolean
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => {
    const {
      size = 'large',
      highlight = false,
      color = 'primary',
      value = undefined,
      startAdornment,
      endAdornment,
      textRight,
      ...rest
    } = props
    return (
      <div className="relative">
        <AdornmentComponent adornment={startAdornment} type="start" />
        <input
          id={props.id}
          ref={ref}
          className={cx(
            'w-full rounded-none border border-solid outline-0 ring-0 placeholder:text-neutral-500 enabled:text-neutral-900 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-500',
            getPadding(size, !!startAdornment, !!endAdornment),
            getFontSize(size),
            getBorderColor(color, highlight),
            getBackgroundColor(color, highlight),
            {
              'text-right': textRight,
            }
          )}
          value={value}
          {...rest}
        />
        <AdornmentComponent
          adornment={endAdornment}
          type="end"
          dataTestId={rest['data-testid']}
        />
        <div />
      </div>
    )
  }
)

interface AdornmentComponentProps {
  adornment?: Adornment
  type: 'start' | 'end'
  dataTestId?: string
}
const AdornmentComponent = ({
  adornment,
  type,
  dataTestId,
}: AdornmentComponentProps) =>
  adornment
    ? React.createElement(
        adornment.onClick ? 'button' : 'div',
        {
          className: cx(
            'absolute bottom-0 top-0 flex items-center justify-center',
            type === 'start' ? 'left-16' : 'right-8',
            {
              'cursor-pointer ring-offset-1 focus-visible:ring-1 ring-primary-600':
                adornment.onClick,
            }
          ),
          'data-testid': `${
            dataTestId ??
            (type === 'start' ? 'start-adornment' : 'end-adornment')
          }-${adornment.variant === 'icon' ? adornment.icon : adornment.text}`,
          onClick: adornment.onClick,
          type: adornment.onClick && 'button',
        },
        adornment.variant === 'icon' ? (
          <Icon size="small" type={adornment.icon} color={adornment.color} />
        ) : (
          <span className="text-neutral-600">{adornment.text}</span>
        )
      )
    : null

export type TextAreaInputProps = Omit<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  'style' | 'className' | 'size' | 'value'
> & {
  color?: TextInputColor
  size?: TextInputSize
  value?: string
  highlight?: boolean
  minHeight?: number
}

export const TextAreaInput = (props: TextAreaInputProps) => {
  const {
    size = 'large',
    highlight = false,
    color = 'primary',
    value = undefined,
    minHeight = 358,
    ...rest
  } = props
  return (
    <textarea
      className={cx(
        `w-full rounded-none border border-solid outline-0 ring-0 placeholder:text-neutral-500 enabled:text-neutral-900 disabled:cursor-not-allowed disabled:bg-neutral-100 disabled:text-neutral-500`,
        getPadding(size, false, false),
        getFontSize(size),
        getBorderColor(color, highlight),
        getBackgroundColor(color, highlight)
      )}
      style={{
        minHeight,
      }}
      value={value}
      {...rest}
    />
  )
}
